<template>
    <Header title="Event" />
    <div class="top_margin"></div>
    <section class="row_padding">
        <main class="header-offset content-wrapper about-wrapper">
            <div class="terms-container">
                <div class="row">
                    <div class="col-sm-8 col-sm-offset-2">
                        <section class="terms-title">
                            <h1>Rio Hill</h1>
                        </section>
                        <section class="terms-title">
                            <h1>Privacy Policy</h1>
                            <hr>
                        </section>

                        <div class="terms-body">
                            <h3>KKB Group. (“us”, “we”, or “our”) operates the <a class="linkColor"
                                    href="https://www.riocolina.com/">https://www.riocolina.com/</a> website (the
                                “Service”).
                            </h3>

                            <hr>
                            <h3>A. Indian Standards / Information Technology Act, 2000</h3>
                            <p>
                                Rio Colina is responsible for the collection, use, and retention of personal information in
                                the
                                sense of the Indian Information Technology Act and thus in accordance with Indian standards.
                            </p>
                            <hr>
                            <h3>B. Type of Information Collected:</h3>
                            <p>
                                When you visit the Platform, we may collect certain non-personal information such as your
                                Internet Protocol ("IP") address, operating system, browser type, and Internet service
                                provider.
                                This type of information does not identify you personally. When you register with the
                                Platform,
                                we may also collect personal information that you provide such as your name, mailing
                                address,
                                email address, phone/mobile number, home country, zip code, and certain other information in
                                event you log in via Facebook, Google+ depending on your privacy settings including but not
                                limited to your name, email address, profile picture, list of friends, education etc.
                                ("Registration Information"). You have the option of also providing to us your fax numbers,
                                gender, age, and/or company name if you choose to register with Rio Colina. If you provide
                                your
                                phone number, it may be displayed in your posting. Further, if you have provided your phone
                                number and posted an advertisement using your account, you agree to receive communications
                                on
                                the provided phone number from our side which may include but not be limited to automated
                                calls
                                or text messages. The communication may require you to confirm and verify that your account
                                has
                                been used to post the relevant advertisement on the Platform. We may also collect additional
                                information that our users provide, such as new or deleted postings, new or deleted
                                comments,
                                keyword searches, scam reports and new contact service providers/ renters. We use a
                                third-party
                                payment gateway for purchases, and other third party companies to monitor site traffic,
                                which
                                may, in some instances, store your information (see Section xxi(D) below). By using this
                                Platform or the Service, you consent to collection, storage, and use of the personal
                                information
                                you provide for any of the services that we offer, and you consent to our collection of any
                                changes or updates that you may provide to any information you provide that is collected by
                                Rio
                                Colina.
                            </p>
                            <hr>
                            <p>
                                By using this Platform, you consent to sharing your geolocation data in order to publish
                                information/advertisements of advertisements offered by users close to your location and
                                vice-versa.
                            </p>
                            <hr>
                            <h3>C. Cookies</h3>
                            <p>
                                We may use cookies to manage our users’ sessions and to store preferences, tracking
                                information,
                                and language selection. Cookies may be used whether you register with us or not. "Cookies"
                                are
                                small text files transferred by a web server to your hard drive and thereafter stored on
                                your
                                computer. The types of information a cookie collects include the date and time you visited,
                                your
                                browsing history, your preferences, and your username. In some instances, our third-party
                                service providers may use cookies on the Platform. We cannot control or access cookies used
                                by
                                third-party service providers. This Privacy Policy covers only Cookies used by us, and not
                                any
                                cookies used by third parties. You have the ability to either accept or decline the use of
                                cookies on your computer, whether you are registered with us or not. Typically, you can
                                configure your browser to not accept cookies. However, declining the use of cookies may
                                limit
                                your access to certain features of the Platform. For example, you may have difficulty
                                logging in
                                or using certain interactive features of the Platform, such as the Rio Colina Forum or
                                Comments
                                feature.
                            </p>
                            <hr>
                            <h3>D. Third Parties</h3>
                            <p>
                                We use third-party service providers to assist us in measuring communications and enquiries
                                on
                                our Platform and generally improving our Platform and to monitor our users’ interests and
                                activities. You hereby authorize Rio Colina and/or third party service providers engaged by
                                Rio
                                Colina to collect, use, store, analyze, reproduce, publish, and adapt (either on its own or
                                through third party service provider) the information in relation to your use of the
                                Platform
                                for the purpose of data analysis and for improving your experience on the Platform. In
                                addition,
                                the Platform may occasionally contain links to Third-Party Sites or provide you information
                                in
                                relation to services that you may avail from any third parties. Information about services
                                that
                                you may choose to avail from these third party service providers may be actively provided to
                                you
                                by Rio Colina in any manner including through its various marketing and communication
                                channels.
                                You acknowledge that this is solely undertaken by Rio Colina to improve your experience in
                                relation to the use of the Platform and the provision of such services shall be subject to
                                such
                                additional terms and conditions of Rio Colina and/or third party service providers. Rio
                                Colina
                                may also offer for free or for a fee, deliverables produced by third party service providers
                                in
                                furtherance of any services that you may have availed from these third party service
                                providers
                                in connection with the advertisement posted on the Platform, without any obligation
                                (monetary or
                                otherwise) towards you. Rio Colina shall not be responsible for any service availed by you
                                from
                                such third parties or any payment made by you to such third parties in connection with the
                                services. You should direct any concern or claims in relation to such services to such third
                                parties. If you click on the links to Third-Party Websites, you leave the Platform. We are
                                not
                                responsible for the content of these Third- Party Websites or for the security of your
                                personal
                                information when you use the Third Party Websites. These third-party service providers and
                                Third-Party Sites may have their own privacy policies governing the storage and retention of
                                your personal information that you may be subject to. They may collect information such as
                                your
                                IP address, browser specification, or operating system. This Privacy Policy does not govern
                                personal information provided to, stored on, or used by these third-party providers and
                                Third-Party Sites. We recommend that when you enter a Third-Party Site, you review the Third
                                Party Site’s privacy policy as it relates to safeguarding your personal information. We use
                                third-party advertising companies to serve ads when you visit the Platform. These companies
                                may
                                use information (not including your name, address, email address, or telephone number) about
                                your visits to the Platform and Third-Party Websites in order to provide advertisements
                                about
                                goods and services of interest to you. On the Platform, Google, as a third-party vendor,
                                uses
                                Cookies to serve ads. Google’s use of the DoubleClick DART Cookie enables it to serve ads to
                                the
                                Platform’s users based on their visit to the Platform and Third-Party Websites. For more
                                information on the DART Cookie see: <a class="linkColor"
                                    href="http://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>.
                                You may opt out of
                                the use of the DART Cookie by Google by visiting the Google Ad and Content Network Privacy
                                Policy at: <a class="linkColor"
                                    href="http://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>.
                                You may opt out of targeting advertising for
                                all NAI member ad networks by visiting: <a class="linkColor"
                                    href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</a>. By
                                publishing an ad
                                on the Platform, you acknowledge and agree that the Content is public and accessible by any
                                third party and that they may appear in search engine results (such as Yahoo!, MSN, Google,
                                Altavista, and other search engines) and in the cache of those search engines, in feeds and
                                Third-Party Websites pursuant to co branding agreements, and that it is the sole
                                responsibility
                                of each of those search engines, Third-Party Websites or RSS web feed resources to update
                                and/or
                                to remove Content from their indexes and their cache. You agree and acknowledge that Rio
                                Colina
                                is not liable for the information published in search results or by any Third-Party Website
                                that
                                carries Rio Colina postings.
                            </p>
                            <hr>
                            <h3>E. How Your Information is used</h3>
                            <p>
                                We may use information provided by you to:
                            </p>
                            <hr>
                            <p>
                                1. enforce our Terms of Use, monitor user activity, such as keyword searches or new
                                postings,
                                and
                                more effectively manage traffic on the Platform.
                            </p>
                            <hr>
                            <p>
                                2. provide customer services, create and manage user accounts.
                            </p>
                            <hr>
                            <p>
                                3. assist you with technical difficulties. Also, we may share with third- party service
                                providers certain information, such as your browser capabilities or operating system, that
                                we
                                have collected in order to better understand which advertisements and services may interest
                                you.
                                We may block users located in certain countries from using the Platform. We may retain such
                                information for as long as is required to fulfill our business objective, even after your
                                account is terminated.
                            </p>
                            <hr>
                            <h3>F. Protecting Your Personal Information</h3>
                            <p>
                                You are a valued customer, and we recognize that protecting your privacy is important to
                                you.
                                For this reason, we are committed to protecting the personal information you provide in a
                                variety of ways. We do not directly process any payments and do not store your credit card
                                information. Secured socket layer ("SSL") technology is used for processing payment
                                transactions
                                with third- party service provider partners, such as PayU, PayPal, and CC Avenue. A unique
                                customer password and user ID may protect your registration information. You should not
                                disclose
                                your password information to anyone and you should always remember to log off if using a
                                shared
                                computer. Lastly, you may utilize the Platform as an anonymous user by not registering. We
                                have
                                taken certain security protections in safeguarding your personal information. However, as
                                with
                                most electronic transactions, no method is 100% safe. While we strive to use a commercially
                                acceptable means to protect the personal information you provide, we cannot guarantee its
                                security. Therefore, you acknowledge and agree that we assume no liability regarding the
                                theft,
                                loss, alteration or misuse of personal or other information or Content, including, without
                                limitation, such information that has been provided to third parties or other users, or with
                                regards to the failure of a third party to abide by the agreement between us and such third
                                party. You may participate in our Forum or utilize our Comments feature. For postings to the
                                Forum, your username will be posted. For postings using the Comments feature, your name and
                                email will only be posted if you provide it in the comment. We strongly discourage posting
                                any
                                information on these features or in any Content posted by you that you do not want others to
                                see. You agree that you are responsible for maintaining the confidentiality of your username
                                and
                                password, and all uses of your account, whether or not you have authorized such use.
                            </p>
                            <hr>
                            <h3>G. Accessing and Modifying Personal Information and Communication Preferences
                            </h3>
                            <p>
                                You may access, remove, review, and/or make changes to personal information that you have
                                provided to us by emailing us on help@villasonrent.com. You need not register in order to
                                post
                                or respond to advertisements on the Platform. If you register or respond to advertisements
                                or
                                posts on the Platform or post any content on the Platform, we may send you certain
                                notifications, advertisements, promotions, surveys, text messages, specials and phone calls
                                in
                                relation to the Services.. We may also send you any legally required notifications and
                                certain
                                notifications, including but not limited to, service related notices or notices regarding a
                                change to any of our policies. For example, we may send you a notice regarding server
                                problems
                                or scheduled maintenance to the Platform. In order to opt-out of receiving these notices,
                                you
                                may need to deactivate your account. You can opt out of certain e-mail communications from
                                us,
                                including our newsletters, advice on buying and selling on the Platform, notifications that
                                a
                                user has commented on your posting, and notifications that you can refresh your posting. We
                                will
                                not change your preferences without your consent. You cannot opt out of receiving
                                transactional
                                communications from Rio Colina related to your account.
                            </p>
                            <hr>
                            <h3>H. Disclosures
                            </h3>
                            <p>
                                On rare occasions, we may be required to disclose your personal information due to legal or
                                regulatory requirements. In such instances, we reserve the right to disclose your personal
                                information as required in order to comply with our legal obligations, including but not
                                limited
                                to complying with court orders, warrants, subpoenas, service of process requirements, or
                                discovery requests. We may also disclose information about our users to law enforcement
                                officers
                                or others, in the good faith belief that such disclosure is reasonably necessary to enforce
                                these Terms and/or Privacy Policy; respond to claims that any Content violates the rights of
                                third-parties; or protect the rights, property, or personal safety of Rio Colina, our users
                                or
                                the general public; or we are in notice of any criminal activity being conducted via the
                                Platform. You agree and acknowledge that we may not inform you prior to or after disclosures
                                made according to this section. If substantially all of our assets are sold or merged into
                                another company, the acquiring company shall be given access to your personal information
                                without your consent. If we sell only a part of our business, the acquiring entity shall
                                have
                                access to your personal information without your consent and we may send you a notice
                                regarding
                                this sale.

                            </p>
                            <hr>
                            <h3>I. Notification of Changes
                            </h3>
                            <p>
                                We reserve the right to change these Terms from time to time, and at our sole discretion. We
                                may
                                send you a notice regarding material changes to these Terms, including the Privacy Policy or
                                the
                                Platform. You should make note of the "last updated date" at the beginning of the Terms.
                                With
                                this information, you should be able to identify if the Terms have been updated recently.

                            </p>
                            <hr>
                            <h3>J. Disputes
                            </h3>
                            <p>
                                Any disputes regarding your privacy are subject to the Terms, including but not limited to
                                any
                                provisions related to indemnification, limitations on damages, and choice of law and forum.

                            </p>


                            <!-- FOOTER -->
                            <div class="container terms_footer">
                                <h3></h3>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
            <!-- /.container -->
        </main>
    </section>
    <Footer />
</template>

<script>
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';

export default {
    name: 'PrivacyPolicy',
    components: { Header, Footer },
    mounted() {
        let recaptchaScript2 = document.createElement("script");
        recaptchaScript2.setAttribute(
            "src",
            "./js/slick/slick.js"
        );
        document.head.appendChild(recaptchaScript2);
        let recaptchaScript3 = document.createElement("script");
        recaptchaScript3.setAttribute(
            "src",
            "./js/slick/slick.min.js"
        );
        document.head.appendChild(recaptchaScript3);
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
</script>

<style scoped>
.terms-container {
    font-family: 'Open Sans', sans-serif;
    color: #262626;
}

.terms-title h1 {
    font-size: 25px;
    font-family: "Itim";
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 20px;
}

.terms-body h4 {
    color: #EB573D;
    font-size: 14px;
    line-height: 25px;
}

.terms-body h3 {
    font-family: "Itim";
    color: #262626;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
    font-size: 19px;
}

.terms-body p {
    font-size: 19px;
    line-height: 25px;
}

.terms-body a {
    color: #EB573D;
}

.terms-body a:hover {
    color: #EB573D;
    text-decoration: none;
}

.terms_footer h3 {
    margin-top: 60px;
    margin-bottom: 125px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.5;
    color: #262626;
}

.terms_footer h3 a:link {
    color: #EB573D;
    text-decoration: none;
}

img {
    width: 100%;
    height: 100%;
}

hr {
    clear: both;
    visibility: hidden;
}

.linkColor {
    color: #0000fc !important;
}
</style>